import React from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import _ from 'underscore';

import constants from '@lib/constants';
import { getErrorMsg } from '@lib/utils';
import { BoundFormControl, BoundLabel } from '@components/forms/';


const errorMessages = {
    'invalid-user': "Sorry, we couldn't find that account.  Please try again.",
    'send-failed': "There was a problem sending your email.  Please try again.",
};


function PasswordReminderForm({ auth, error, loading, cancel, sendReminder }) {
    const onCancelClicked = e => {
        e.preventDefault();
        cancel();
    };

    const isFormValid = () => {
        const valid = auth.model.validate();
        return !(valid && _.has(valid, 'username'));
    };

    const onFormSubmit = e => {
        e.preventDefault();

        if (isFormValid()) {
            sendReminder();
        }
    };

    return (
        <Form method="post" onSubmit={onFormSubmit}>
            <h2>Forgotten Your Password?</h2>

            {error &&
                <Alert variant="danger">
                    {getErrorMsg(error, errorMessages)}
                </Alert>
            }

            <p>Enter the email address used to set up your Voipfone Partner account in
            the box below.  We will then email you a link to reset your password.</p>

            <p>If you cant remember the email address for your account,
            please <a href={`${constants.BASE_VF_URL}/Contact_Us.php`}
                    target="_blank">contact our customer services team</a>.</p>

            <hr />

            <p>
                <BoundLabel bind={auth} field="username">Email Address</BoundLabel>
                <BoundFormControl
                    bind={auth}
                    field="username"
                    type="text"
                    disabled={loading}
                />
            </p>
            <p className="d-flex">
                <Button
                    type="submit"
                    id="saveBtn"
                    className="flex-fill"
                    disabled={loading || !isFormValid()}
                >Send Password Reset Link</Button>
            </p>
            <p><a href="#" onClick={onCancelClicked}>
                <ArrowLeftCircleFill /> Back to Sign In
            </a></p>
        </Form>
    );
}


export default PasswordReminderForm;

import React from 'react';
import { Button } from 'react-bootstrap';


function ResetButton({ bind, label = 'Reset' }) {
    return (
        <Button
            type="reset"
            variant="secondary"
            disabled={!bind.model.isResetable()}
            onClick={() => bind.model.reset()}
        >{label}</Button>
    );
}


export default ResetButton;

import Backbone from 'backbone';
import _ from 'underscore';
import { mixin as validation } from 'backbone-validation';


export class BaseCollection extends Backbone.Collection {
    _allowedMethods = ['create', 'read', 'update', 'delete'];

    /*
     * This class isn't intended to be used as generally as the parent class.
     * We shouldn't be passing pre-built collections or lots of options so we're
     * overriding the default constructor behaviour to take initialisation
     * properties which are set on the new instance, eg:
     *
     * const c = new BaseCollection({ test: 'testing' });
     * console.log(c.test);
     * > 'testing'
     */
    constructor(initProps) {
        super([]);
        for (let [key, val] of _.pairs(initProps)) {
            this[key] = val;
        }
    }

    sync(method, model, options) {
        if (_.contains(this._allowedMethods, method)) {
            this[method](model, options);
        }
    }

    create(model, options) {}
    read(model, options) {}
    update(model, options) {}
    delete(model, options) {}
}


export class BaseModel extends Backbone.Model {
    _allowedMethods = ['create', 'read', 'update', 'delete'];
    _prevSavedAttrs = {};

    constructor(props) {
        super(props);
        this.set({
            ...this.constructor.defaults,
            ...this.attributes,
        });
        this.metadata = new Backbone.Model({
            saveable: false,
            resetable: false,
        });
        this._prevSavedAttrs = _.clone(this.attributes);
        this.on('sync', this.updateAfterSync);
        this.on('change', this.onAttrChange);
    }

    sync(method, model, options) {
        if (_.contains(this._allowedMethods, method)) {
            this[method](model, options);
        }
    }

    create(model, options) {}
    read(model, options) {}
    update(model, options) {}
    delete(model, options) {}

    validate = validation.validate

    updateAfterSync() {
        this._previousAttributes = _.clone(this.attributes);
        this._prevSavedAttrs = _.clone(this.attributes);
        this.onAttrChange();
    }

    onAttrChange = () => {
        const changedSinceSave = !_.isEqual(this._prevSavedAttrs, this.attributes);
        this.metadata.set({
            resetable: changedSinceSave,
            saveable: changedSinceSave && this.isValid(),
        });
    }

    reset() {
        this.set(this._prevSavedAttrs);
        this.trigger('reset');
    }

    isResetable = () => {
        return this.metadata.get('resetable');
    }

    isSaveable = () => {
        return this.metadata.get('saveable');
    }
}

import React from 'react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { useMachine } from '@xstate/react';

import { useModel } from '@lib/hooks';

import { PasswordResetModel, TwoFactorModel } from './models';
import PasswordResetForm from './password-reset-form';
import machine from './reset-password-machine';
import TwoFactorForm from './two-factor-form';

import '@components/auth-dialog/auth-dialog.scss';


function ResetPassword({ token }) {
    const [state, send] = useMachine(machine);
    const pwReset = useModel(PasswordResetModel);
    const twoFactor = useModel(TwoFactorModel);

    return (
        <React.Fragment>
            {state.matches('init') &&
                <PasswordResetForm
                    pwReset={pwReset}
                    state={state.context}
                    onSubmit={() => send('RESET', {
                        password: pwReset.password,
                        token: token,
                        pin: null,
                    })}
                />
            }

            {state.matches('requesting-2f') &&
                <TwoFactorForm
                    twoFactor={twoFactor}
                    state={state.context}
                    onSubmit={() => send('RESET', {
                        password: pwReset.password,
                        token: token,
                        pin: twoFactor.pin,
                    })}
                    onCancel={() => send('CANCEL')}
                />
            }

            {state.matches('done') &&
                <Redirect to="/" />
            }
        </React.Fragment>
    );
}


export default ResetPassword;

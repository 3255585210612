
import _ from 'underscore';

const RETRY_ON_ERRORS = ['invalid-csrf-token'];
const RELOAD_ON_ERRORS = ['missing-auth-token'];


function prepareData(data) {
    return _.pairs(data)
        .map(p => `${encodeURIComponent(p[0])}=${encodeURIComponent(p[1])}`)
        .join('&');
}


class API {
    baseUrl = ''
    hasSession = false
    token = null
    pendingRequests = 0

    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    init() {
        return new Promise(async (resolve, reject) => {
            if (!this.hasSession) {
                await this.acquireSession();
            }

            if (!this.token) {
                await this.acquireCsrfToken();
            }

            resolve();
        });
    }

    reset() {
        this.hasSession = false;
        this.token = null;
    }

    acquireSession() {
        return new Promise((resolve, reject) => {
            this._post('__session__', {})
                .then(() => {
                    this.hasSession = true;
                    resolve();
                })
                .catch(err => {
                    if (err.error === 'invalid-auth-token') {
                        this.hasSession = false;
                        reject();
                    } else {
                        this.hasSession = true;
                        resolve();
                    }
                });
        });
    }

    acquireCsrfToken() {
        return new Promise((resolve, reject) => {
            this._get('__token__')
                .then(data => {
                    this.token = data;
                    resolve();
                })
                .catch(err => {
                    this.token = null;
                    reject();
                });
        });
    }

    // These are the public methods for HTTP actions.  They always attempt to initialise the session and token
    // if those aren't yet initialised.
    async get(path, responseType) {
        await this.init();
        return this._get(path, responseType);
    }

    async post(path, data, responseType) {
        await this.init();
        return this._post(path, data, responseType);
    }

    async put(path, data, responseType) {
        await this.init();
        return this._put(path, data, responseType);
    }

    async delete(path, responseType) {
        await this.init();
        return this._delete(path, responseType);
    }

    // These are private methods for HTTP actions.  They circumvents session/token initialisation.
    _get(path, responseType) {
        return this._send_request('GET', path, {}, responseType);
    }

    _post(path, data, responseType) {
        const headers = {
            'X-CSRF-Token': this.token,
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        data = prepareData(data);
        return this._send_request('POST', path, data, responseType, headers);
    }

    _put(path, data, responseType) {
        // Note: this method is untested
        const headers = {
            'X-CSRF-Token': this.token,
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        data = prepareData(data);
        return this._send_request('PUT', path, data, responseType, headers);
    }

    _delete(path) {
        // Note: this method is untested
        const headers = {
            'X-CSRF-Token': this.token,
        };
        return this._send_request('DELETE', path, {}, undefined, headers);
    }

    _send_request(method, path, data, responseType, headers, refreshTokenOnError=true) {
        responseType = responseType ? responseType : 'text';
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open(method, this.baseUrl + path);
            req.withCredentials = true;

            _.pairs(headers)
                .map(p => req.setRequestHeader(p[0], p[1]));

            req.onload = () => {
                if (req.status === 200) {
                    switch (responseType) {
                        case 'json':
                            resolve(JSON.parse(req.responseText));
                            break;

                        case 'text':
                        default:
                            resolve(req.responseText);
                    }
                } else {
                    let error = { error: 'undefined', message: req.statusText, data: null };
                    try {
                        error = JSON.parse(req.responseText);
                    } catch (e) { }

                    if (_.contains(RELOAD_ON_ERRORS, error.error)) {
                        window.location.reload();
                    } else if (refreshTokenOnError && _.contains(RETRY_ON_ERRORS, error.error)) {
                        this.acquireCsrfToken()
                            .then(() => {
                                headers['X-CSRF-Token'] = this.token;
                                this._send_request(method, path, data, responseType, headers, false)
                                    .then(data => {
                                        resolve(data);
                                    })
                                    .catch(err => {
                                        reject(err);
                                    });
                            });

                    } else {
                        reject(error);
                    }
                }

                this.pendingRequests--;
                window.hasLoaded = this.pendingRequests == 0;
            };
            req.onerror = (e) => {
                reject(`Error (${method} request): ${e}`);
            };

            this.pendingRequests++;
            window.hasLoaded = this.pendingRequests == 0;
            req.send(data);
        });
    }
}


export default new API(process.env.REACT_APP_API_BASE_URL);

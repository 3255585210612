import React from 'react';
import { Alert, Button, Form } from 'react-bootstrap';

import { getErrorMsg } from '@lib/utils';
import { BoundFormControl } from '@components/forms/';


const errorMessages = {};


function PasswordResetForm({ pwReset, state, onSubmit }) {
    const onFormSubmit = e => {
        e.preventDefault();

        if (pwReset.model.isValid()) {
            onSubmit();
        }
    };

    return (
        <Form method="post" onSubmit={onFormSubmit}>
            <h2>Reset Password</h2>

            {state.error &&
                <Alert variant="danger">
                    {getErrorMsg(state.error, errorMessages)}
                </Alert>
            }

            <p>Please enter and confirm a new password in the form
            below. You will then be asked to log in with your new
            password.</p>

            <Alert variant="info">
                Your new password must be at least 8 characters long, and include
                at least one each of an uppercase letter, lowercase letter, number
                and symbol ({'~`! @#$%^&*()_-+={[}]|\\:;"\'<,>.?/'}).
            </Alert>

            <BoundFormControl
                bind={pwReset}
                field="password"
                type="password"
                placeholder="New Password"
                disabled={state.isLoading}
            />
            <br />
            <BoundFormControl
                bind={pwReset}
                field="passwordConfirm"
                type="password"
                placeholder="Confirm New Password"
                disabled={state.isLoading}
            />
            <br />
            <p className="d-flex">
                <Button
                    type="submit"
                    className="flex-fill"
                    disabled={state.isLoading || !pwReset.model.isValid()}
                >Set New Password</Button>
            </p>
        </Form>
    );
}


export default PasswordResetForm;


import api from '@lib/api';


export function checkAuth(username, password, pin) {
    const params = {
        username,
        password,
        type: 'partner'
    };

    if (pin) {
        params.challenge = pin;
    }

    // Unauthenticated session tokens are only valid for 1 hour so request one
    // before logging in
    // Ideally when attempting to log in without an auth-token the API would
    // return "missing-auth-token-login" but it returns "invalid-credentials"
    api.reset();
    return api.post('auth/login', params);
}


export function requestPasswordReminder(username) {
    return api.post('partners/password/reset', { username });
}


export function resetPassword(new_password, reset_token, pin) {
    const params = {
        'new-password': new_password,
        'reset-token': reset_token,
    };

    if (pin) {
        params.challenge = pin;
    }

    return api.post('partners/password/set', params);
}


export function checkActiveUser() {
    return new Promise((resolve, reject) => {
        api.get('auth/status', 'json')
            .then(data => {
                if (data.type == 'anonymous') {
                    reject();
                } else {
                    resolve({
                        partnerId: data['partner_id'],
                        userId: data['id'],
                    });
                }
            })
            .catch(reject);
    });
}


export function logoutUser() {
    return new Promise((resolve, reject) => {
        api.post('auth/logout')
            .then(() => {
                api.reset();
                api.init().finally(() => resolve());
            })
            .catch(reject);
    });
}

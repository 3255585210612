import { useState } from 'react';
import _ from 'underscore';

import useDidMount from './use-did-mount';
import useWillUnmount from './use-will-unmount';

import { formChangeHandler } from '../utils';


export function useCollection(collCls, params) {
    const fetch = _.has(params, 'fetch') ? params.fetch : true;
    const initialVals = _.has(params, 'init') ? params.init : null;
    const context = _.has(params, 'context') ? params.context : null;

    const build = coll => ([
        ...coll.map(model => ({
            ...model.attributes,
            cid: model.cid,
            changeHandler: formChangeHandler(model),
            model,
        }))
    ])

    const [inited, setInited] = useState(false);
    const [coll,] = useState(inited ? null : new collCls(initialVals));
    const [boundArr, setBoundArr] = useState(inited ? null : build(coll));

    if (!inited) setInited(true);

    useDidMount(() => {
        coll.context = context
        coll.on(
            'add change remove reset sync update',
            () => setBoundArr(build(coll))
        );

        if (fetch) coll.fetch();
    });

    useWillUnmount(() => coll.off());

    return [boundArr, {
        collection: coll,
    }];
}


export default useCollection;

import React, { useContext } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { BrowserRouter as Router, NavLink, Route, Switch } from "react-router-dom";
import { List } from 'react-bootstrap-icons';

import { UserContext } from '@lib/store';
import { useConfirmDialog } from '@lib/hooks';
import DynamicContent from '@components/dynamic-content';

import Footer from './footer';
import './main-win.scss';


const TopNavLink = ({ to, children, ...props }) => (
    <NavLink
        to={to}
        className="nav-link"
        activeClassName="active"
        {...props}
    >{children}</NavLink>
);


function MainWin() {
    const context = useContext(UserContext);
    const [SignoutDialog, showDialog] = useConfirmDialog({
        title: 'Sign Out',
        msg: 'Are you sure you want to sign out?',
        onOkClicked: context.userLoggedOut,
    });

    return (
        <Router>
            <Navbar expand="lg" fixed="top">
                <Container id="mainwin">
                    <Navbar.Brand>
                        <NavLink to="/">
                            <img
                                src="/logo.png"
                                alt="Voipfone Technical Partners"
                                title="Voipfone Technical Partners"
                            />
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <List size="25" />
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <TopNavLink to="/statements">
                                Statements
                            </TopNavLink>
                            <TopNavLink to="/accounts">
                                Accounts
                            </TopNavLink>
                            <TopNavLink to="/profile">
                                Profile
                            </TopNavLink>
                            <TopNavLink to="/support">
                                Help &amp; Support
                            </TopNavLink>
                            <Nav.Link
                                href="/logout"
                                id="logoutBtn"
                                onClick={showDialog}
                            >Sign Out</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Container className="maincontent">
                <Switch>
                    <Route path="*" render={({ location }) => (
                        <DynamicContent path={location?.pathname} />
                    )} />
                </Switch>
            </Container>

            <Footer />
            <SignoutDialog />
        </Router>
    );
}


export default MainWin;

import React, { useState } from 'react';
import { Machine } from 'xstate';
import { useMachine } from '@xstate/react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Col, Container, Navbar, Row } from 'react-bootstrap';

import constants from '@lib/constants';
import { useModel } from '@lib/hooks';
import { checkAuth, requestPasswordReminder } from '@lib/auth';

import { AuthModel, TwoFactorModel } from './models';
import LoginForm from './login-form';
import TwoFactorForm from './two-factor-form';
import PasswordReminderForm from './password-reminder-form';
import PasswordReminderSent from './password-reminder-sent';
import ResetPassword from './reset-password';

import './auth-dialog.scss';


const machine = Machine({
    id: 'auth-dialog',
    initial: 'user-login',
    states: {
        'user-login': {
            on: {
                SUCCESS: 'done',
                FORGOT_PASSWORD: 'password-reminder',
                TWO_FACTOR: 'user-login-2f',
            },
            exit: () => {
                new Cookies().remove('password-was-reset');
            }
        },

        'user-login-2f': {
            on: {
                SUCCESS: 'done',
                CANCEL: 'user-login',
            },
        },

        'password-reminder': {
            on: {
                SUCCESS: 'password-reminder-sent',
                CANCEL: 'user-login',
            },
        },

        'password-reminder-sent': {
            on: {
                RETRY: 'password-reminder',
                CANCEL: 'user-login',
            },
        },

        done: {
            entry: () => {
                window.location.reload();
            },
            type: 'final',
        },
    },
});


function AuthDialog() {
    const [state, send] = useMachine(machine);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const auth = useModel(AuthModel);
    const twoFactor = useModel(TwoFactorModel);

    const doUserAuth = () => {
        setLoading(true);

        checkAuth(auth.username, auth.password, twoFactor.pin)
            .then(() => {
                new Cookies().set('last-login-username', auth.username);
                send('SUCCESS')
            })
            .catch(err => {
                if (err.error === 'challenge-required') {
                    send('TWO_FACTOR');
                } else {
                    setError(err.error);
                }
            })
            .finally(() => setLoading(false));
    };

    const doSendReminder = () => {
        setLoading(true);

        requestPasswordReminder(auth.username)
            .then(() => {
                new Cookies().set('last-login-username', auth.username);
                send('SUCCESS');
            })
            .catch(err => setError(err.error))
            .finally(() => setLoading(false));
    };

    const onCancelled = () => {
        setError(null);
        send('CANCEL');
    };

    return (
        <React.Fragment>
            <Navbar expand="lg">
                <Container id="mainwin">
                    <Navbar.Brand>
                        <img
                            src="/logo.png"
                            alt="Voipfone Technical Partners"
                            title="Voipfone Technical Partners"
                        />
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Container className="auth-dialog">
                <div className="inner">
                    <Row className="content">
                        <Col md={6} xs={12} className="intro">
                            <h2>Not Registered?</h2>

                            <p>This web application is for registered Voipfone Partners
                            and their users.  If you are not a registered Voipfone
                            Partner, please see
                            our <a href={constants.BASE_VF_URL} target="_blank">main
                            web site</a> for details on how to sign up.</p>
                        </Col>
                        <Col md={6} xs={12}>
                            <Router>
                                <Switch>
                                    <Route
                                        path="/password/set/:token"
                                        component={({ match }) =>
                                            <ResetPassword
                                                token={match?.params?.token}
                                            />
                                        }
                                    >
                                    </Route>
                                    <Route path="*">
                                        {state.matches('user-login') &&
                                            <LoginForm
                                                userAuth={doUserAuth}
                                                forgotPassword={() => send('FORGOT_PASSWORD')}
                                                auth={auth}
                                                loading={loading}
                                                error={error}
                                            />
                                        }

                                        {state.matches('user-login-2f') &&
                                            <TwoFactorForm
                                                twoFactor={twoFactor}
                                                state={{
                                                    error: error,
                                                    isLoading: loading,
                                                }}
                                                onSubmit={doUserAuth}
                                                onCancel={onCancelled}
                                            />
                                        }

                                        {state.matches('password-reminder') &&
                                            <PasswordReminderForm
                                                sendReminder={doSendReminder}
                                                cancel={onCancelled}
                                                auth={auth}
                                                loading={loading}
                                                error={error}
                                            />
                                        }

                                        {state.matches('password-reminder-sent') &&
                                            <PasswordReminderSent
                                                email={auth.username}
                                                retry={() => send('RETRY')}
                                                cancel={onCancelled}
                                            />
                                        }
                                    </Route>
                                </Switch>
                            </Router>
                        </Col>
                    </Row>
                </div>
            </Container>
        </React.Fragment>
    );
}


export default AuthDialog;

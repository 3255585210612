import React from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import Cookies from 'universal-cookie';

import { BoundFormControl, BoundLabel } from '@components/forms/';
import { getErrorMsg } from '@lib/utils';


const errorMessages = {
    'invalid-credentials': "Your username or password was incorrect. Please " +
        "try again.",
};


function LoginForm({ auth, error, forgotPassword, loading, userAuth }) {
    const onFormSubmit = e => {
        e.preventDefault();

        if (auth.model.isValid()) {
            userAuth();
        }
    };

    const onForgotPasswordClicked = e => {
        e.preventDefault();
        forgotPassword();
    };

    const showResetMessage = new Cookies().get('password-was-reset');

    return (
        <Form method="post" onSubmit={onFormSubmit}>
            <h2>Existing Users</h2>

            {error &&
                <Alert variant="danger">
                    {getErrorMsg(error, errorMessages)}
                </Alert>
            }

            {!error && !showResetMessage &&
                <Alert variant="info">
                    Sign in to your account
                </Alert>
            }

            {!error && showResetMessage &&
                <Alert variant="info">
                    To complete your password reset, please sign in with
                    your new password
                </Alert>
            }

            <p>
                <BoundLabel bind={auth} field="username">Email Address</BoundLabel>
                <BoundFormControl
                    bind={auth}
                    field="username"
                    type="text"
                    disabled={loading}
                    autoFocus
                />
            </p>
            <p>
                <BoundLabel bind={auth} field="password">Password</BoundLabel>
                <BoundFormControl
                    bind={auth}
                    field="password"
                    type="password"
                    disabled={loading}
                    hideError
                />
            </p>
            <p className="text-right">
                <a href="#" onClick={onForgotPasswordClicked}>
                        Forgotten Password?
                </a>
            </p>
            <p className="d-flex">
                <Button
                    type="submit"
                    id="saveBtn"
                    className="flex-fill"
                    disabled={loading || !auth.model.isValid()}
                >Sign In</Button>
            </p>
        </Form>
    );
}


export default LoginForm;

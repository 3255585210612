import React, { useState } from 'react';

import ConfirmDialog from '@components/confirm-dialog';


/**
 * A hook to use a confirm dialog
 *
 * Example usage:
 * <code>
 * function MyComponent() {
 *     const [SignoutDialog, showDialog] = useConfirmDialog({
 *         title: 'Confirm Something', // Optional title
 *         msg: 'Are you sure you want to do this?',
 *         onOkClicked: () => doStuff(),
 *     });
 *     return (
 *         <div>
 *             ... component content ...
 *             <SignoutDialog />
 *         </div>
 *     );
 * }
 * </code>
 *
 * @param {*} props See @components/confirm-dialog. Everything except onHide is passed on
 */
function useConfirmDialog(props) {
    const [show, setShow] = useState(false);
    const showDialog = (e) => {
        e?.preventDefault && e.preventDefault();
        setShow(true);
    }

    const DialogComponent = () => (
        show && <ConfirmDialog
            {...props}
            onHide={() => setShow(false)}
        />
    );

    return [DialogComponent, showDialog];
}


export default useConfirmDialog;

import React, { useEffect, useState } from 'react';
import _ from 'underscore';


const VALID_PATH_CHARS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_/';
const DEFAULT_PAGE = 'statements';


const LoadingPage = () => (
    <p>Loading &hellip;</p>
);


const NotFoundError = () => (
    <p>Page not found!</p>
);


function DynamicContent({ path }) {
    // For some reason, react barfs on the DynamicComponent's type if we try to
    // split these into separate states.  Probably not worth investigating, but
    // worth noting here to prevent anyone accidentally copying this approach
    // to state definition elsewhere.
    const [page, setPage] = useState({
        DynamicComponent: null,
        title: '',
    });

    useEffect(() => {
        (async () => {
            path = path && path !== '/'
                ? path.slice(1)
                : DEFAULT_PAGE;

            setPage({
                DynamicComponent: LoadingPage,
                title: 'Loading',
            });

            try {
                if (!_.every(_.map(path, c => _.contains(VALID_PATH_CHARS, c)))) {
                    throw new Error('invalid page format');
                }

                const {
                    default: DynamicComponent,
                    title,
                } = await import(`@content/${path}-page/`);
                
                setPage({
                    DynamicComponent,
                    title,
                });
            } catch (err) {
                setPage({
                    DynamicComponent: NotFoundError,
                    title: 'Page Not Found',
                });
            }
        })();
    }, [path]);

    document.title = `Voipfone Partner Portal - ${page.title}`;
    return page.DynamicComponent ? <page.DynamicComponent /> : '';
}


export default DynamicContent;

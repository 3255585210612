import React from 'react';
import { Button, Modal } from 'react-bootstrap';


function ConfirmDialog({ msg, title = 'Confirm', onOkClicked, onCancelClicked, onHide }) {
    const _onOkClicked = () => {
        onOkClicked && onOkClicked();
        onHide && onHide();
    }
    const _onCancelClicked = () => {
        onCancelClicked && onCancelClicked();
        onHide && onHide();
    }

    return (
        <Modal show={true}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Body>{msg}</Modal.Body>
            <Modal.Footer>
                <Button id="confirm-cancel-btn" onClick={_onCancelClicked}>Cancel</Button>
                <Button id="confirm-ok-btn" onClick={_onOkClicked}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}


export default ConfirmDialog;

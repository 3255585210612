import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import _ from 'underscore';

import { useDidMount } from '@lib/hooks';

import './forms.scss';


function BoundFormControl({ autofocus = false, bind, dataType, field,
		type = 'text', hideError = false, ...props }) {
	const fieldElement = useRef();

	useDidMount(() => {
		autofocus && fieldElement.current && fieldElement.current.focus();
	});

	const errors = bind && bind.model.validate();
	const myError = !hideError && _.has(errors, field) && errors[field];
	const FormElement = type === 'switch' ? Form.Switch : Form.Control;
	const showError = Boolean(myError) && _.has(bind, field) && Boolean(bind[field]);

	return (
		<React.Fragment>
			<FormElement
				ref={fieldElement}
				name={field}
				type={type}
				isInvalid={myError}
				value={bind && bind[field]}
				onChange={bind.changeHandler(field, dataType)}
				{...props}
				bind={bind}
				field={field}
			/>
			{showError &&
				<Form.Control.Feedback type="invalid">
		            {myError}
				</Form.Control.Feedback>
			}
		</React.Fragment>
	);
}


export default BoundFormControl;

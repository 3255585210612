import Cookies from 'universal-cookie';

import { BaseModel } from '@lib/models';
import * as validators from '@lib/validators';


// Only the username and password are defined in this model as we want to be
// able to pass them between screens, whereas the pin is defined in the 2-factor
// form itself because it should be cleared each time that screen is accessed.
class AuthModel extends BaseModel {
    static defaults = {
        username: new Cookies().get('last-login-username'),
        password: '',
    }

    validation = {
        username: {
            required: true,
            pattern: 'email',
        },
        password: {
            required: true,
        },
    }
}


class PasswordResetModel extends BaseModel {
    static defaults = {
        token: '',
        password: '',
        passwordConfirm: '',
    }

    validation = {
        password: validators.password,
        passwordConfirm: {
            fn: (val) => {
                if (val != this.get('password')) {
                    return 'Passwords must match';
                }
            }
        },
    }
}


class TwoFactorModel extends BaseModel {
    static defaults = {
        pin: '',
    }

    validation = {
        pin: {
            required: true,
            length: 6,
            pattern: 'digits',
        },
    }
}


export {
    AuthModel,
    PasswordResetModel,
    TwoFactorModel,
}

import { Machine, assign } from 'xstate';
import Cookies from 'universal-cookie';

import { resetPassword } from '@lib/auth';


const machine = Machine({
    initial: 'init',

    context: {
        error: null,
        isLoading: false,
    },

    states: {
        'init': {
            on: {
                RESET: 'resetting',
                TWO_FACTOR: 'requesting-2f',
            },
        },

        'requesting-2f': {
            on: {
                RESET: 'resetting',
                CANCEL: 'init',
            },
        },

        'resetting': {
            entry: assign({
                isLoading: true,
            }),
            invoke: {
                src: (_, event) => resetPassword(event.password, event.token, event.pin),
                onDone: 'done',
                onError: [
                    {
                        target: 'requesting-2f',
                        cond: (_, event) => {
                            return event.data.error === 'challenge-required';
                        },
                    },
                    {
                        target: 'init',
                        actions: assign({
                            error: (_, event) => event.data.error,
                        }),
                    },
                ],
            },
            exit: assign({
                isLoading: false,
            }),
        },

        'done': {
            entry: () => {
                new Cookies().set('password-was-reset', true);
            },
            type: 'final',
        },
    },
});


export default machine;

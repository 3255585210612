import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

import { getErrorMsg } from '@lib/utils';

import useDidMount from './use-did-mount';


function useNotify({ model, successMsg, errorMsgs={} }) {
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState(null);

    useDidMount(() => {
        model?.on('update', () => {
            setMessage(successMsg);
            setMessageType('success');
        });

        model?.on('error', error => {
            setMessage(getErrorMsg(error, errorMsgs));
            setMessageType('danger');
        });
    });

    const AlertComponent = () => (
        message && <Alert
            variant={messageType}
            onClose={() => setMessage(null)}
            dismissible
        >
            {message}
        </Alert>
    );

    return [AlertComponent, setMessage, setMessageType];
}


export default useNotify;

import React from 'react';
import { Button } from 'react-bootstrap';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';


function PasswordReminderSent({ email, retry, cancel }) {
    const onRetryBtnClicked = e => {
        e.preventDefault();
        retry();
    };

    const onCancelClicked = () => {
        cancel();
        return false;
    };

    return (
        <React.Fragment>
            <h2>Password Reset Sent</h2>

            <p>An email has been sent to {email} with instructions for resetting
            your password.  If it has not arrived within 20 minutes, click the
            button below to try again.</p>

            <p className="d-flex">
                <Button
                    onClick={onRetryBtnClicked}
                    id="saveBtn"
                    className="flex-fill"
                >Resend Email</Button>
            </p>
            <p><a href="#" onClick={onCancelClicked}>
                <ArrowLeftCircleFill /> Back to Sign In
            </a></p>
        </React.Fragment>
    );
}


export default PasswordReminderSent;

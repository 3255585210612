import React from 'react';
import { Machine, assign } from 'xstate';
import { useMachine } from '@xstate/react';

import { UserContext } from '@lib/store';
import { checkActiveUser, logoutUser } from '@lib/auth';
import AuthDialog from '@components/auth-dialog/';
import MainWin from '@components/main-win/';


const machine = Machine({
    id: 'app',
    initial: 'init',
    context: {
        partnerId: null,
        userId: null,
    },
    states: {
        'init': {
            always: 'checking-active-user',
        },

        'checking-active-user': {
            invoke: {
                src: 'checkActiveUser',
                onDone: {
                    target: 'running-app',
                    actions: assign({
                        partnerId: (_, event) => event.data.partnerId,
                        userId: (_, event) => event.data.userId,
                    }),
                },
                onError: {
                    target: 'authenticating-user',
                    actions: assign({
                        partnerId: null,
                        userId: null,
                    }),
                },
            },
        },

        'authenticating-user': {
            on: { USER_AUTHED: 'checking-active-user' },
        },

        'running-app': {
            on: { USER_DEAUTHED: 'logout-user' },
        },

        'logout-user': {
            invoke: {
                src: logoutUser,
                onDone: 'checking-active-user',
                onError: 'checking-active-user',
            },
        },
    }
});


function App() {
    const [state, send] = useMachine(machine, {
        services: {
            checkActiveUser,
        },
    });

    const context = {
        ...state.context,
        userAuthenticated: () => send('USER_AUTHED'),
        userLoggedOut: () => send('USER_DEAUTHED'),
    };

    return (
        <UserContext.Provider value={context}>
            { state.matches('checking-active-user') &&
                <p className="text-center">Contacting Server</p>
            }

            { state.matches('authenticating-user') &&
                <AuthDialog />
            }

            { state.matches('running-app') &&
                <MainWin />
            }
        </UserContext.Provider>
    );
}


export default App;

import React from 'react';
import { Button } from 'react-bootstrap';


function SaveButton({ bind, label = 'Save Changes' }) {
    return (
        <Button
            type="submit"
            variant="primary"
            disabled={!bind.model.isSaveable()}
        >{label}</Button>
    );
}


export default SaveButton;


const BASE_VF_URL = 'https://www.voipfone.co.uk';
const BASE_BLOG_URL = 'https://www.voipfoneblog.co.uk';
const BASE_CP_URL = 'https://my.voipfone.co.uk';
const BASE_STATUS_URL = 'https://www.voipfonestatus.co.uk';
const VF_EMAIL = 'info@voipfone.co.uk';
const VF_TEL = '020 7043 5555';
const MIN_WITHDRAW_BALANCE = 50;

export default {
    BASE_VF_URL,
    BASE_BLOG_URL,
    BASE_CP_URL,
    BASE_STATUS_URL,
    VF_EMAIL,
    VF_TEL,
    MIN_WITHDRAW_BALANCE,
};

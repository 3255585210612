import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import parsePhoneNumberFromString from 'libphonenumber-js';
import _ from 'underscore';
import _s from 'underscore.string';


export function price(price, removeZeroDecimal=false) {
    const isZeroDecimal = price % 1 === 0;
    return _.isNumber(price) ?
        _s.numberFormat(price, removeZeroDecimal && isZeroDecimal ? 0 : 2) : '';
}


export function shortDate(rawDate) {
    return rawDate && dayjs(rawDate).isValid()
        ? rawDate.format('DD MMM YYYY')
        : '';
}


export function shortDateRange(fromDate, toDate, invalidValue='') {
    if (!fromDate || !toDate || !dayjs(fromDate).isValid() || !dayjs(toDate).isValid()) {
        return invalidValue;
    } else if (fromDate.date() === 1 && toDate.date() === 1 && toDate.diff(fromDate, 'months') === 1) {
        return fromDate.format('MMM YYYY');
    } else if (fromDate.date() === 1 && toDate.date() === 1 && fromDate.month() === 0 && toDate.month() === 0 && toDate.diff(fromDate, 'months') === 12) {
        return fromDate.format('YYYY');
    } else if (fromDate.year() !== toDate.year()) {
        return `${shortDate(fromDate)} - ${shortDate(toDate)}`;
    } else if (fromDate.month() !== toDate.month()) {
        return `${fromDate.format('DD MMM')} - ${shortDate(toDate)}`;
    } else if (fromDate.date() !== toDate.date()) {
        return `${fromDate.format('DD')} - ${shortDate(toDate)}`;
    } else {
        return shortDate(fromDate);
    }
}


export function shortTime(rawDate, allowHour24=false) {
    dayjs.extend(advancedFormat);
    return rawDate?.isValid?.() ?
        rawDate.format(allowHour24 && rawDate.hour() === 0 &&
            rawDate.minute() === 0 ? 'kk:mm' : 'HH:mm') : '';
}


export function ukPostcode(val, withSpace) {
    if (!val || _s.isBlank(val)) return '';
    if (!_.isBoolean(withSpace)) withSpace = true;

    val = val.replace(/\s/g, '').toUpperCase();

    if (withSpace) {
        var lastNumber = val.match(/(\d)[^\d]*$/);

        if (lastNumber) {
            val = _s.insert(val, lastNumber.index, ' ');
        }
    }

    return val;
}


export function phoneNumber(val) {
    const number = parsePhoneNumberFromString(val, 'GB');

    if (_.isUndefined(number)) {
        return val;
    } else if (number.countryCallingCode === '44') {
        return number.formatNational();
    } else {
        return number.formatInternational();
    }
}


export function phoneNumberE164(val) {
    const number = parsePhoneNumberFromString(val, 'GB');

    if (_.isUndefined(number)) {
        return val;
    } else {
        return number.number;
    }
}


/**
 * Format an extension object showing the number and name inline
 *
 * @param  Object/String  ext   The extension model object or extension
 *                              number
 * @param  String         name  Optional extension name - only used if a
 *                              number is provided for the first param
 * @return String
 */
export function extensionFull(ext, name) {
    let number = ext;

    if (ext && ext.get) {
        number = ext.get('number');
        name = ext.get('name');
    } else if (_.isObject(ext)) {
        number = ext.number;
        name = ext.name;
    }

    if (name && name.length > 0) {
        name = ` - ${name}`;
    } else {
        name = '';
    }

    // return '<b>' + number + '</b>' + name; // @TODO: Restore the bold tags
    return `${number}${name}`;
}


export function destinationName(val) {
    const type = _.isString(val) && val.replace(/[0-9]/g, '');
    const number = _.isString(val) && val.replace(/[^0-9]/g, '');

    switch (type) {
        case 'e':
        case 'ext_':
            return number;
        case 'master':
            return 'Master Group';
        case 'g':
        case 'group_':
            return `Group ${number}`;
        case 'q':
        case 'queue':
            return 'Queue Master Group';
        case 'i':
        case 'queue_':
            return `Queue Group ${number}`;
        case 'ivr':
        case 'ivr_':
            return 'IVR';
        case 'vm_master':
            return `Master Voicemail`;
        case 'vm_ext_':
            return `${number} Voicemail`;
        case 'vm_group_':
            return `Group ${number} Voicemail`;
        default:
            return val;
    }
}


export function listItems(items, maxLength=25, joinStr=', ') {
    let i;
    let moreStr = '';

    for (i = 0; i < items.length; i++) {
        const total = items.slice(0, i + 1).reduce((sum, val) => sum + val.length, 0);

        if (i > 0 && total >= maxLength) {
            break;
        }
    }

    if (i < items.length) {
        moreStr = ` and ${items.length - i} more`;
    }

    return items.slice(0, i).join(joinStr) + moreStr;
}


export default {
    destinationName,
    extensionFull,
    listItems,
    phoneNumber,
    phoneNumberE164,
    price,
    shortDate,
    shortDateRange,
    shortTime,
    ukPostcode,
};

import { useState } from 'react';
import _ from 'underscore';

import useDidMount from './use-did-mount';
import useWillUnmount from './use-will-unmount';

import { formChangeHandler } from '../utils';


function useModel(modelCls, params) {
    const fetch = _.has(params, 'fetch') ? params.fetch : true;
    const initialVals = _.has(params, 'init') ? params.init : null;
    const context = _.has(params, 'context') ? params.context : null;

    const build = model => ({
        ...model.attributes,
        cid: model.cid,
        changeHandler: formChangeHandler(model),
        model,
    })

    const [inited, setInited] = useState(false);
    const [model,] = useState(
        inited ? null : new modelCls(initialVals));
    const [boundModel, setBoundModel] = useState(
        inited ? null : build(model));

    if (inited) setInited(true);

    useDidMount(() => {
        model.context = context;
        model.on('change sync', () => setBoundModel(build(model)));

        if (fetch) model.fetch();
    });

    useWillUnmount(() => model.off());

    return boundModel;
}


export default useModel;

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import dayjs from 'dayjs';

import constants from '@lib/constants';

import './footer.scss';


function Footer() {
    return (
        <footer>
            <Container>
                <div className="content">
                    <div className="logo">
                        <img
                            src="/vf-logo.png"
                            title="Voipfone"
                            alt="Voipfone"
                            style={{ maxWidth: '200px', }}
                        />
                    </div>

                    <Row>
                        <Col md={6} lg={4}>
                            <p className="text-yellow text-md">All About Voipfone</p>

                            <p>
                                <a href={`${constants.BASE_VF_URL}/Case_Studies.php`}>
                                    Case Studies</a><br />
                                <a href={`${constants.BASE_VF_URL}/Press_And_Awards.php`}>
                                    Press &amp; Awards</a><br />
                                <a href={`${constants.BASE_VF_URL}/Our_Network.php`}>
                                    Our Network</a><br />
                                <a href={`${constants.BASE_VF_URL}/What_Is_Voip.php`}>
                                    What is VoIP?</a><br />
                                <a href={`${constants.BASE_VF_URL}/jobs.php`}>
                                    Jobs at Voipfone</a><br />
                                <a href={constants.BASE_BLOG_URL} target="_blank" rel="noopener">
                                    Voipfone Blog</a><br />
                            </p>

                            <p className="text-yellow text-md">Products &amp; Services</p>

                            <p>
                                <a href={`${constants.BASE_VF_URL}/plans/comparison`}>
                                    Voipfone Plans</a><br />
                                <a href={`${constants.BASE_VF_URL}/EP_UK_Telephone_Numbers.php`}>
                                    Telephone Numbers</a><br />
                                <a href={`${constants.BASE_VF_URL}/switchboard_and_hosted_PBX_services.php`}>
                                    Hosted PBX Services</a><br />
                                <a href={`${constants.BASE_VF_URL}/features.php`}>
                                    Standard Features</a><br />
                                <a href={`${constants.BASE_VF_URL}/broadband.php`}>
                                    Business Broadband</a><br />
                                <a href={`${constants.BASE_VF_URL}/softphone_download.php`}>
                                    Softphone Download</a><br />
                            </p>
                        </Col>
                        <Col md={6} lg={4}>
                            <p className="text-yellow text-md">Customer Services</p>

                            <p>
                                <a href={constants.BASE_CP_URL} target="_blank" rel="noopener">
                                    Control Panel Login</a><br />
                                <a href={`${constants.BASE_VF_URL}/call-cost-calculator`} target="_blank" rel="noopener">
                                    Call Cost Calculator</a><br />
                                <a href={`${constants.BASE_VF_URL}/service-numbers.php`}>
                                    Service Numbers</a><br />
                                <a href={constants.BASE_STATUS_URL} target="_blank" rel="noopener">
                                    Service Status</a><br />
                                <a href={`${constants.BASE_VF_URL}/FAQ.php`}>
                                    FAQs</a><br />
                                <a href={`${constants.BASE_VF_URL}/Contact_Us.php`}>
                                    Contact Us</a>
                            </p>

                            <p className="text-yellow text-md">Terms &amp; Conditions</p>

                            <p>
                                <a href={`${constants.BASE_VF_URL}/policies/terms`}>
                                    Terms &amp; Conditions</a><br />
                                <a href={`${constants.BASE_VF_URL}/Usage_Policy.php`}>
                                    Usage Policy</a><br />
                                <a href={`${constants.BASE_VF_URL}/code_of_practice.php`}>
                                    Code of Practice</a><br />
                                <a href={`${constants.BASE_VF_URL}/complaints_procedures.php`}>
                                    Complaint &amp; Dispute Resolution</a><br />
                                <a href={`${constants.BASE_VF_URL}/your-data.php`}>
                                    Privacy Notice</a><br />
                                <a href={`${constants.BASE_VF_URL}/cookie_policy.php`}>
                                    Cookie Policy</a>
                            </p>
                        </Col>
                        <Col md={6} lg={4}>
                            <p className="text-yellow text-md">Contact Us</p>

                            <p className="contact">
                                <strong>Head Office</strong><br />
                                iNet Telecoms&reg; Ltd<br />
                                Sovereign House<br />
                                227 Marsh Wall<br />
                                London<br />
                                E14 9SD<br />
                                United Kingdom
                            </p>

                            <p>
                                Email: {constants.VF_EMAIL}<br />
                                Telephone: {constants.VF_TEL}<br />

                                <a
                                    href="https://www.facebook.com/voipfone"
                                    target="_blank"
                                    rel="noopener"
                                    className="social"
                                >
                                    <img
                                        src="/facebook.png"
                                        title="Facebook"
                                        alt="Facebook"
                                        width="30"
                                        height="30"
                                        style={{ margin: '4px' }}
                                    />
                                </a>

                                <a
                                    href="https://twitter.com/voipfone"
                                    target="_blank"
                                    rel="noopener"
                                    className="social"
                                >
                                    <img
                                        src="/twitter.png"
                                        title="Twitter"
                                        alt="Twitter"
                                        width="30"
                                        height="30"
                                        style={{ margin: '4px' }}
                                    />
                                </a>

                                <a
                                    href="https://www.instagram.com/voipfone"
                                    target="_blank"
                                    rel="noopener"
                                    className="social"
                                >
                                    <img src="/instagram.png"
                                        title="Instagram"
                                        alt="Instagram"
                                        width="30"
                                        height="30"
                                        style={{ margin: '4px' }}
                                    />
                                </a>

                                <a
                                    href="https://www.youtube.com/voipfone"
                                    target="_blank"
                                    rel="noopener"
                                    className="social"
                                >
                                    <img
                                        src="/youtube.png"
                                        title="YouTube"
                                        alt="YouTube"
                                        width="30"
                                        height="30"
                                        style={{ margin: '4px' }}
                                    />
                                </a>

                                <a
                                    href="https://www.linkedin.com/company/voipfone"
                                    target="_blank"
                                    rel="noopener"
                                    className="social"
                                >
                                    <img
                                        src="/linkedin.png"
                                        title="LinkedIn"
                                        alt="LinkedIn"
                                        width="30"
                                        height="30"
                                        style={{ margin: '4px' }}
                                    />
                                </a>
                            </p>

                            <p>
                                <a
                                    href="https://commscouncil.uk"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img
                                        src="/comms-council.png"
                                        title="Comms Council UK"
                                        alt="Comms Council UK"
                                        width="145"
                                        height="59"
                                    />
                                </a>
                            </p>
                        </Col>
                    </Row>
                </div>
                <div className="copyright">
                    <p>
                        Copyright 2004 - {dayjs().format('YYYY')},
                        iNet Telecoms &reg; Ltd.
                        All rights reserved
                    </p>
                </div>
            </Container>
        </footer>
    );
}


export default Footer;

import React from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';

import constants from '@lib/constants';
import { BoundFormControl } from '@components/forms';
import { getErrorMsg } from '@lib/utils';

import { BoundLabel } from 'components/forms';


const errorMessages = {
    'invalid-challenge': "There was a problem with your verification PIN.  Please try again.",
};


function TwoFactorForm({ twoFactor, state, onSubmit, onCancel }) {
    const onCancelClicked = e => {
        e.preventDefault();
        onCancel();
    };

    const onFormSubmit = e => {
        e.preventDefault();

        if (twoFactor.model.isValid()) {
            onSubmit();
        }
    };

    return (
        <Form method="post" onSubmit={onFormSubmit}>
            <h2>Verify Your Sign In</h2>

            {state.error &&
                <Alert variant="danger">
                    {getErrorMsg(state.error, errorMessages)}
                </Alert>
            }

            <p>Your account is configured to require a second verification step.
            Please enter the verification PIN from your authentication app to
            complete your sign in.</p>

            <p>If you have any problems signing in with your authentication app,
            please <a href={`${constants.BASE_VF_URL}/Contact_Us.php`} target="_blank">
            contact our customer services team</a>.</p>

            <hr />

            <p>
                <BoundLabel bind={twoFactor} field="pin">Verification PIN</BoundLabel>
                <BoundFormControl
                    bind={twoFactor}
                    field="pin"
                    type="text"
                    disabled={state.isLoading}
                    autofocus
                />
            </p>
            <p className="d-flex">
                <Button
                    type="submit"
                    id="saveBtn"
                    className="flex-fill"
                    disabled={state.isLoading || !twoFactor.model.isValid()}
                >Verify Me</Button>
            </p>
            <p><a href="#" onClick={onCancelClicked}>
                <ArrowLeftCircleFill /> Back to Sign In
            </a></p>
        </Form>
    );
}

export default TwoFactorForm;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import ResetButton from './reset-button';
import SaveButton from './save-button';


function ButtonPanel({ children }) {
    return (
        <Card bg="light">
            <Card.Body>
                <Row>
                    {children}
                </Row>
            </Card.Body>
        </Card>
    );
}


ButtonPanel.Left =({ children }) => (
    <Col md="6" className="text-left">
        {React.Children.map(children, child => (
            <React.Fragment>{child}&nbsp;</React.Fragment>
        ))}
    </Col>
);


ButtonPanel.Right = ({ children }) => (
    <Col md="6" className="text-right">
        {React.Children.map(children, child => (
            <React.Fragment>&nbsp;{child}</React.Fragment>
        ))}
    </Col>
);


ButtonPanel.Default = ({ bind, saveLabel }) => (
    <ButtonPanel>
        <ButtonPanel.Left>
            <ResetButton bind={bind} />
        </ButtonPanel.Left>
        <ButtonPanel.Right>
            <SaveButton bind={bind} label={saveLabel} />
        </ButtonPanel.Right>
    </ButtonPanel>
);


export default ButtonPanel;

import React, { useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import _ from 'underscore';

import './sort-code.scss';


function SortCode({ bind, field, className }) {
    const [parts, setParts] = useState(['', '', '']);

    bind.model.on('reset', () => setParts(['', '', '']));

    const onValueChanged = (index, target) => {
        const nextInput = target?.nextSibling?.nextSibling;
        let newParts = [...parts];
        newParts[index] = target.value;
        setParts(newParts);
        bind?.model?.set(field, _.compact(newParts).length == 0 ? '' : newParts.join('-'));

        if (nextInput && target.value.length == target.maxLength) {
            nextInput.focus();
        }
    };

    const isInvalid = className.indexOf('is-invalid') >= 0;

    return (
        <Row className={isInvalid ? 'is-invalid' : ''}>
            <Col xs="auto">
                <InputGroup className="sort-code-input">
                    <Form.Control
                        type="text"
                        name="sortCode"
                        value={parts[0]}
                        isInvalid={isInvalid}
                        onChange={e => onValueChanged(0, e.target)}
                        htmlSize="2"
                        maxLength="2"
                    />
                    <InputGroup.Text>-</InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="sortCode"
                        value={parts[1]}
                        isInvalid={isInvalid}
                        onChange={e => onValueChanged(1, e.target)}
                        htmlSize="2"
                        maxLength="2"
                    />
                    <InputGroup.Text>-</InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="sortCode"
                        value={parts[2]}
                        isInvalid={isInvalid}
                        onChange={e => onValueChanged(2, e.target)}
                        htmlSize="2"
                        maxLength="2"
                    />
                </InputGroup>
            </Col>
        </Row>
    );
}


export default SortCode;

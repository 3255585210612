
import _ from 'underscore';


export const DEFAULT_ERROR_MSG = "An error occurred.  Please try again.";


export function getErrorMsg(reason, errorMessages={}) {
    if (_.has(errorMessages, reason)) {
        return errorMessages[reason];
    } else if (_.has(errorMessages, '')) {
        return errorMessages[''];
    } else {
        return DEFAULT_ERROR_MSG;
    }
}


export function isAlpha(str) {
    return Boolean(str && str.match(/^[a-zA-Z]+$/));
}


export function convertType (val, dataType) {
    switch (dataType) {
        case 'bool': return _.contains(['1', 'on', 'true', 'yes'], String(val));
        case 'int': return parseInt(val);
        case 'float': return parseFloat(val);
        case 'str': return String(val);
        default: return val;
    }
}


export function formChangeHandler(model) {
    return (field, dataType) => {
        return ({ target }) => {
            if (!target) {
                return;
            } else if (_.has(target, 'checked')) {
                model.set(field, target.checked);
            } else {
                model.set(field, convertType(target.value, dataType));
            }
        }
    }
}


export default {
    DEFAULT_ERROR_MSG,
    convertType,
    formChangeHandler,
    getErrorMsg,
    isAlpha,
};

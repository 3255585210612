import React from 'react';
import { Form } from 'react-bootstrap';
import _ from 'underscore';


function BoundLabel({ bind, children, field, ...props }) {
    const hasError = _.has(bind.model.validationError, field);

    return (
        <Form.Label
            htmlFor={field}
            className={hasError ? 'has-error' : ''}
            {...props}
        >{children}</Form.Label>
    );
}


export default BoundLabel;
